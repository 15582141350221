import { Character } from "./interfaces";

export const tank = [
  {
    name: "D.VA",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/ca114f72193e4d58a85c087e9409242f1a31e808cf4058678b8cbf767c2a9a0a.png",
  },
  {
    name: "DOOMFIST",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/13750471c693c1a360eb19d5ace229c8599a729cd961d72ebee0e157657b7d18.png",
  },
  {
    name: "HAZARD",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/612ae1e6d28125bd4d4d18c2c4e5b004936c094556239ed24a1c0a806410a020.png",
  },
  {
    name: "JUNKER QUEEN",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/cef2406b2244b80506f83b8fb9ebaf214f41fa8795cbeef84026cd8018561d04.png",
  },
  {
    name: "MAUGA",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/9ee3f5a62893091d575ec0a0d66df878597086374202c6fc7da2d63320a7d02e.png",
  },
  {
    name: "ORISA",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/71e96294617e81051d120b5d04b491bb1ea40e2933da44d6631aae149aac411d.png",
  },
  {
    name: "RAMATTRA",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/3e0367155e1940a24da076c6f1f065aacede88dbc323631491aa0cd5a51e0b66.png",
  },
  {
    name: "REINHARDT",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/490d2f79f8547d6e364306af60c8184fb8024b8e55809e4cc501126109981a65.png",
  },
  {
    name: "ROADHOG",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/72e02e747b66b61fcbc02d35d350770b3ec7cbaabd0a7ca17c0d82743d43a7e8.png",
  },
  {
    name: "SIGMA",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/cd7a4c0a0df8924afb2c9f6df864ed040f20250440c36ca2eb634acf6609c5e4.png",
  },
  {
    name: "WINSTON",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/bd9c8e634d89488459dfc1aeb21b602fa5c39aa05601a4167682f3a3fed4e0ee.png",
  },
  {
    name: "WRECKING BALL",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/5c18e39ce567ee8a84078f775b9f76a2ba891de601c059a3d2b46b61ae4afb42.png",
  },
  {
    name: "ZARYA",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/8819ba85823136640d8eba2af6fd7b19d46b9ee8ab192a4e06f396d1e5231f7a.png",
  },
];

export const dps = [
  {
    name: "ASHE",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/8dc2a024c9b7d95c7141b2ef065590dbc8d9018d12ad15f76b01923986702228.png",
  },
  {
    name: "BASTION",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/4d715f722c42215072b5dd0240904aaed7b5285df0b2b082d0a7f1865b5ea992.png",
  },
  {
    name: "CASSIDY",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/6cfb48b5597b657c2eafb1277dc5eef4a07eae90c265fcd37ed798189619f0a5.png",
  },
  {
    name: "ECHO",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/f086bf235cc6b7f138609594218a8385c8e5f6405a39eceb0deb9afb429619fe.png",
  },
  {
    name: "GENJI",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/4edf5ea6d58c449a2aeb619a3fda9fff36a069dfbe4da8bc5d8ec1c758ddb8dc.png",
  },
  {
    name: "HANZO",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/aecd8fa677f0093344fab7ccb7c37516c764df3f5ff339a5a845a030a27ba7e0.png",
  },
  {
    name: "JUNKRAT",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/037e3df083624e5480f8996821287479a375f62b470572a22773da0eaf9441d0.png",
  },
  {
    name: "MEI",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/1533fcb0ee1d3f9586f84b4067c6f63eca3322c1c661f69bfb41cd9e4f4bcc11.png",
  },
  {
    name: "PHARAH",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/f8261595eca3e43e3b37cadb8161902cc416e38b7e0caa855f4555001156d814.png",
  },
  {
    name: "REAPER",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/2edb9af69d987bb503cd31f7013ae693640e692b321a73d175957b9e64394f40.png",
  },
  {
    name: "SOJOURN",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/a53bf7ad9d2f33aaf9199a00989f86d4ba1f67c281ba550312c7d96e70fec4ea.png",
  },
  {
    name: "SOLDIER: 76",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/20b4ef00ed05d6dba75df228241ed528df7b6c9556f04c8070bad1e2f89e0ff5.png",
  },
  {
    name: "SOMBRA",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/bca8532688f01b071806063b9472f1c0f9fc9c7948e6b59e210006e69cec9022.png",
  },
  {
    name: "SYMMETRA",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/7f2024c5387c9d76d944a5db021c2774d1e9d7cbf39e9b6a35b364d38ea250ac.png",
  },
  {
    name: "TORBJORN",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/1309ab1add1cc19189a2c8bc7b1471f88efa1073e9705d2397fdb37d45707d01.png",
  },
  {
    name: "TRACER",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/a66413200e934da19540afac965cfe8a2de4ada593d9a52d53108bb28e8bbc9c.png",
  },
  {
    name: "VENTURE",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/7e33dd756c8a1abca519af6c3bf26813f2f81d39885373539efcf8442c4bc647.png",
  },
  {
    name: "WIDOWMAKER",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/a714f1cb33cc91c6b5b3e89ffe7e325b99e7c89cc8e8feced594f81305147efe.png",
  },
];

export const support = [
  {
    name: "ANA",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/3429c394716364bbef802180e9763d04812757c205e1b4568bc321772096ed86.png",
  },
  {
    name: "BAPTISTE",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/f979896f74ba22db2a92a85ae1260124ab0a26665957a624365e0f96e5ac5b5c.png",
  },
  {
    name: "BRIGITTE",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/48392820c6976ee1cd8dde13e71df85bf15560083ee5c8658fe7c298095d619a.png",
  },
  {
    name: "ILLARI",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/5ea986038f9d307bd4613d5e6f2c4c8e7f15f30ceeeabbdd7a06637a38f17e1f.png",
  },
  {
    name: "JUNO",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/585b2d60cbd3c271b6ad5ad0922537af0c6836fab6c89cb9979077f7bb0832b5.png",
  },
  {
    name: "KIRIKO",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/088aff2153bdfa426984b1d5c912f6af0ab313f0865a81be0edd114e9a2f79f9.png",
  },
  {
    name: "LIFEWEAVER",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/39d4514f1b858bc228035b09d5a74ed41f8eeefc9a0d1873570b216ba04334df.png",
  },
  {
    name: "LUCIO",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/e2ff2527610a0fbe0c9956f80925123ef3e66c213003e29d37436de30b90e4e1.png",
  },
  {
    name: "MERCY",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/2508ddd39a178d5f6ae993ab43eeb3e7961e5a54a9507e6ae347381193f28943.png",
  },
  {
    name: "MOIRA",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/000beeb5606e01497897fa9210dd3b1e78e1159ebfd8afdc9e989047d7d3d08f.png",
  },
  {
    name: "ZENYATTA",
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/71cabc939c577581f66b952f9c70891db779251e8e70f29de3c7bf494edacfe4.png",
  },
];

export const Characters: Character[] = [
  {
    name: "mauga",
    gender: "male",
    role: "tank",
    race: "human",
    org: "talon",
    projectiletype: "hitscan",
    releaseyear: 2024,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/9ee3f5a62893091d575ec0a0d66df878597086374202c6fc7da2d63320a7d02e.png",
  },
  {
    name: "d.va",
    gender: "female",
    role: "tank",
    race: "human",
    org: "overwatch",
    projectiletype: "hitscan",
    releaseyear: 2016,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/ca114f72193e4d58a85c087e9409242f1a31e808cf4058678b8cbf767c2a9a0a.png",
  },
  {
    name: "doomfist",
    gender: "male",
    role: "tank",
    race: "human",
    org: "talon",
    projectiletype: "projectile",
    releaseyear: 2017,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/13750471c693c1a360eb19d5ace229c8599a729cd961d72ebee0e157657b7d18.png",
  },
  {
    name: "hazard",
    gender: "male",
    role: "tank",
    race: "human",
    org: "phreaks",
    projectiletype: "projectile",
    releaseyear: 2024,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/612ae1e6d28125bd4d4d18c2c4e5b004936c094556239ed24a1c0a806410a020.png",
  },
  {
    name: "junker queen",
    gender: "female",
    role: "tank",
    race: "human",
    org: "junker",
    projectiletype: "hitscan",
    releaseyear: 2022,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/cef2406b2244b80506f83b8fb9ebaf214f41fa8795cbeef84026cd8018561d04.png",
  },
  {
    name: "orisa",
    gender: "female",
    role: "tank",
    race: "omnic",
    org: "overwatch",
    projectiletype: "projectile",
    releaseyear: 2017,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/71e96294617e81051d120b5d04b491bb1ea40e2933da44d6631aae149aac411d.png",
  },
  {
    name: "ramattra",
    gender: "male",
    role: "tank",
    race: "omnic",
    org: "null sector",
    projectiletype: "projectile/melee",
    releaseyear: 2022,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/3e0367155e1940a24da076c6f1f065aacede88dbc323631491aa0cd5a51e0b66.png",
  },
  {
    name: "reinhardt",
    gender: "male",
    role: "tank",
    race: "human",
    org: "overwatch",
    projectiletype: "melee",
    releaseyear: 2016,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/490d2f79f8547d6e364306af60c8184fb8024b8e55809e4cc501126109981a65.png",
  },
  {
    name: "roadhog",
    gender: "male",
    role: "tank",
    race: "human",
    org: "junker",
    projectiletype: "projectile",
    releaseyear: 2016,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/72e02e747b66b61fcbc02d35d350770b3ec7cbaabd0a7ca17c0d82743d43a7e8.png",
  },
  {
    name: "sigma",
    gender: "male",
    role: "tank",
    race: "human",
    org: "talon",
    projectiletype: "projectile",
    releaseyear: 2019,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/cd7a4c0a0df8924afb2c9f6df864ed040f20250440c36ca2eb634acf6609c5e4.png",
  },
  {
    name: "winston",
    gender: "male",
    role: "tank",
    race: "animal",
    org: "overwatch",
    projectiletype: "hitscan/beam",
    releaseyear: 2016,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/bd9c8e634d89488459dfc1aeb21b602fa5c39aa05601a4167682f3a3fed4e0ee.png",
  },
  {
    name: "wrecking ball",
    gender: "male",
    role: "tank",
    race: "animal",
    org: "junker",
    projectiletype: "hitscan/projectile",
    releaseyear: 2018,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/5c18e39ce567ee8a84078f775b9f76a2ba891de601c059a3d2b46b61ae4afb42.png",
  },
  {
    name: "zarya",
    gender: "female",
    role: "tank",
    race: "human",
    org: "overwatch",
    projectiletype: "beam/projectile",
    releaseyear: 2016,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/8819ba85823136640d8eba2af6fd7b19d46b9ee8ab192a4e06f396d1e5231f7a.png",
  },
  {
    name: "ashe",
    gender: "female",
    role: "dps",
    race: "human",
    org: "deadlock rebels",
    projectiletype: "hitscan",
    releaseyear: 2018,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/8dc2a024c9b7d95c7141b2ef065590dbc8d9018d12ad15f76b01923986702228.png",
  },
  {
    name: "bastion",
    gender: "n/a",
    role: "dps",
    race: "omnic",
    org: "null sector/overwatch",
    projectiletype: "hitscan",
    releaseyear: 2016,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/4d715f722c42215072b5dd0240904aaed7b5285df0b2b082d0a7f1865b5ea992.png",
  },
  {
    name: "cassidy",
    gender: "male",
    role: "dps",
    race: "human",
    org: "deadlock rebels/overwatch",
    projectiletype: "hitscan",
    releaseyear: 2016,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/6cfb48b5597b657c2eafb1277dc5eef4a07eae90c265fcd37ed798189619f0a5.png",
  },
  {
    name: "echo",
    gender: "female",
    role: "dps",
    race: "omnic",
    org: "overwatch",
    projectiletype: "projectile",
    releaseyear: 2020,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/f086bf235cc6b7f138609594218a8385c8e5f6405a39eceb0deb9afb429619fe.png",
  },
  {
    name: "genji",
    gender: "male",
    role: "dps",
    race: "human",
    org: "shimada clan/overwatch",
    projectiletype: "projectile",
    releaseyear: 2016,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/4edf5ea6d58c449a2aeb619a3fda9fff36a069dfbe4da8bc5d8ec1c758ddb8dc.png",
  },
  {
    name: "hanzo",
    gender: "male",
    role: "dps",
    race: "human",
    org: "shimada clan",
    projectiletype: "projectile",
    releaseyear: 2016,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/aecd8fa677f0093344fab7ccb7c37516c764df3f5ff339a5a845a030a27ba7e0.png",
  },
  {
    name: "junkrat",
    gender: "male",
    role: "dps",
    race: "human",
    org: "junker",
    projectiletype: "projectile",
    releaseyear: 2016,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/037e3df083624e5480f8996821287479a375f62b470572a22773da0eaf9441d0.png",
  },
  {
    name: "mei",
    gender: "female",
    role: "dps",
    race: "human",
    org: "overwatch",
    projectiletype: "projectile",
    releaseyear: 2016,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/1533fcb0ee1d3f9586f84b4067c6f63eca3322c1c661f69bfb41cd9e4f4bcc11.png",
  },
  {
    name: "pharah",
    gender: "female",
    role: "dps",
    race: "human",
    org: "overwatch/helix",
    projectiletype: "projectile",
    releaseyear: 2016,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/f8261595eca3e43e3b37cadb8161902cc416e38b7e0caa855f4555001156d814.png",
  },
  {
    name: "reaper",
    gender: "male",
    role: "dps",
    race: "human",
    org: "talon",
    projectiletype: "hitscan",
    releaseyear: 2016,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/2edb9af69d987bb503cd31f7013ae693640e692b321a73d175957b9e64394f40.png",
  },
  {
    name: "sojourn",
    gender: "female",
    role: "dps",
    race: "human",
    org: "overwatch",
    projectiletype: "hitscan/projectile",
    releaseyear: 2022,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/a53bf7ad9d2f33aaf9199a00989f86d4ba1f67c281ba550312c7d96e70fec4ea.png",
  },
  {
    name: "soldier: 76",
    gender: "male",
    role: "dps",
    race: "human",
    org: "overwatch",
    projectiletype: "hitscan",
    releaseyear: 2016,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/20b4ef00ed05d6dba75df228241ed528df7b6c9556f04c8070bad1e2f89e0ff5.png",
  },
  {
    name: "sombra",
    gender: "female",
    role: "dps",
    race: "human",
    org: "talon",
    projectiletype: "hitscan",
    releaseyear: 2016,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/bca8532688f01b071806063b9472f1c0f9fc9c7948e6b59e210006e69cec9022.png",
  },
  {
    name: "symmetra",
    gender: "female",
    role: "dps",
    race: "human",
    org: "vishkar",
    projectiletype: "beam/projectile",
    releaseyear: 2016,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/7f2024c5387c9d76d944a5db021c2774d1e9d7cbf39e9b6a35b364d38ea250ac.png",
  },
  {
    name: "torbjorn",
    gender: "male",
    role: "dps",
    race: "human",
    org: "overwatch",
    projectiletype: "projectile",
    releaseyear: 2016,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/1309ab1add1cc19189a2c8bc7b1471f88efa1073e9705d2397fdb37d45707d01.png",
  },
  {
    name: "tracer",
    gender: "female",
    role: "dps",
    race: "human",
    org: "overwatch",
    projectiletype: "hitscan",
    releaseyear: 2016,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/a66413200e934da19540afac965cfe8a2de4ada593d9a52d53108bb28e8bbc9c.png",
  },
  {
    name: "venture",
    gender: "n/a",
    role: "dps",
    race: "human",
    org: "wayfinder",
    projectiletype: "projectile",
    releaseyear: 2024,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/7e33dd756c8a1abca519af6c3bf26813f2f81d39885373539efcf8442c4bc647.png",
  },
  {
    name: "widowmaker",
    gender: "female",
    role: "dps",
    race: "human",
    org: "talon",
    projectiletype: "hitscan",
    releaseyear: 2016,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/a714f1cb33cc91c6b5b3e89ffe7e325b99e7c89cc8e8feced594f81305147efe.png",
  },
  {
    name: "ana",
    gender: "female",
    role: "support",
    race: "human",
    org: "overwatch",
    projectiletype: "hitscan/projectile",
    releaseyear: 2016,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/3429c394716364bbef802180e9763d04812757c205e1b4568bc321772096ed86.png",
  },
  {
    name: "baptiste",
    gender: "male",
    role: "support",
    race: "human",
    org: "talon",
    projectiletype: "hitscan",
    releaseyear: 2019,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/f979896f74ba22db2a92a85ae1260124ab0a26665957a624365e0f96e5ac5b5c.png",
  },
  {
    name: "brigitte",
    gender: "female",
    role: "support",
    race: "human",
    org: "overwatch",
    projectiletype: "melee",
    releaseyear: 2018,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/48392820c6976ee1cd8dde13e71df85bf15560083ee5c8658fe7c298095d619a.png",
  },
  {
    name: "illari",
    gender: "female",
    role: "support",
    race: "human",
    org: "n/a",
    projectiletype: "hitscan",
    releaseyear: 2023,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/5ea986038f9d307bd4613d5e6f2c4c8e7f15f30ceeeabbdd7a06637a38f17e1f.png",
  },
  {
    name: "juno",
    gender: "female",
    role: "support",
    race: "human",
    org: "project red promise",
    projectiletype: "hitscan",
    releaseyear: 2024,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/585b2d60cbd3c271b6ad5ad0922537af0c6836fab6c89cb9979077f7bb0832b5.png",
  },
  {
    name: "kiriko",
    gender: "female",
    role: "support",
    race: "human",
    org: "shimada clan",
    projectiletype: "projectile",
    releaseyear: 2022,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/088aff2153bdfa426984b1d5c912f6af0ab313f0865a81be0edd114e9a2f79f9.png",
  },
  {
    name: "lifeweaver",
    gender: "male",
    role: "support",
    race: "human",
    org: "vishkar",
    projectiletype: "projectile",
    releaseyear: 2023,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/39d4514f1b858bc228035b09d5a74ed41f8eeefc9a0d1873570b216ba04334df.png",
  },
  {
    name: "lucio",
    gender: "male",
    role: "support",
    race: "human",
    org: "overwatch",
    projectiletype: "projectile",
    releaseyear: 2016,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/e2ff2527610a0fbe0c9956f80925123ef3e66c213003e29d37436de30b90e4e1.png",
  },
  {
    name: "mercy",
    gender: "female",
    role: "support",
    race: "human",
    org: "overwatch",
    projectiletype: "beam/projectile",
    releaseyear: 2016,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/2508ddd39a178d5f6ae993ab43eeb3e7961e5a54a9507e6ae347381193f28943.png",
  },
  {
    name: "moira",
    gender: "female",
    role: "support",
    race: "human",
    org: "talon",
    projectiletype: "beam",
    releaseyear: 2017,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/000beeb5606e01497897fa9210dd3b1e78e1159ebfd8afdc9e989047d7d3d08f.png",
  },
  {
    name: "zenyatta",
    gender: "male",
    role: "support",
    race: "omnic",
    org: "n/a",
    projectiletype: "projectile",
    releaseyear: 2016,
    img: "https://d15f34w2p8l1cc.cloudfront.net/overwatch/71cabc939c577581f66b952f9c70891db779251e8e70f29de3c7bf494edacfe4.png",
  },
];
